/* ContactSubmissions.css */

.container {
  max-width: 800px;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.origin-title {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
  font-size: 22px;
  color: #333;
  border-bottom: 2px solid #ddd;

  padding-bottom: 10px;
  margin-bottom: 10px;
}

.submission-list {
  list-style-type: none;
  padding: 0;
}

.submission-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
}

.submission-item p {
  margin: 5px 0;
}

.submission-item p strong {
  color: #555;
}

.empty-message {
  font-style: italic;
  color: #999;
}
