.sidebar {
  top: 0;
  left: 0;
  width: 180px;
  height: 100%;
  z-index: 100;
  background-color: #1d2327;
  color: #f0f0f1 !important;
  z-index: 100;
  padding: 10px;
  overflow: hidden;
}

.sidebar button,
.sidebar a {
  display: flex !important;
  align-items: center;
  font-size: 14px;
  height: 40px;
  gap: 10px;
  color: #f0f0f1;
  display: block;
  cursor: pointer;
  background: transparent;
}

.sidebar i {
  font-size: 20px;
}

.menu-toggle {
  background-color: transparent;
}

.menu-toggle img {
  max-width: 30px !important;
}

.close-menu-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}

.menu-toggle-container span {
  white-space: nowrap;
}

.menu-toggle-container.minimized span {
  display: none;
}
