.App {
  font-family: Arial, sans-serif;
}

.body-wrapper {
  margin-top: 45px;
}

a:hover,
button:hover {
  color: #2271b1 !important;
}

.App i {
  font-size: 22px !important;
}

.main-btn {
  display: block;
  border: 1px solid #c3c4c7;
  height: auto;
  margin-bottom: 0;
  padding: 3px 16px;
  background-color: #fff;
  color: #646970;
  line-height: 1.7;
  box-shadow: 0 0 0 transparent;
  transition: box-shadow 0.1s linear;
}

.main-btn:hover {
  background-color: #eeeeee !important;
  color: #2c3338 !important;
}
/* 

botão + ícone
título
subtítulo
main-hover




*/
