.home-container {
  height: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex: 1;
}

.home-dashboard-menu {
  display: flex;
}

.home-content {
  flex: 1;
  
}
