.header-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  background-color: #1d2327;
  z-index: 101;
  width: 100%;
  height: 45px;
}

.header-container{
    padding: 20px 10px;
    color: #f0f0f1;
    display: flex;
    align-items: center;
    z-index: 100;
    height: 100%;
    gap: 40px;
    justify-content: space-between;
}

.header-btns-container{
  display: flex;
  gap: 20px;
  align-items: center;
}

.header-container h1{
  font-size: 20px;
}

.logo a{
  display: flex;
  gap: 10px;
  align-items: center;

}

.logo i{
  font-size: 30px;
}

