.footer__container {
  background-color: #2c3338;
}

.footer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 40px 20px;
  color: rgba(255, 255, 255, 0.936);
  flex-wrap: wrap;
  max-width: 1250px;
  margin: auto;
}

.footer h3 {
  margin-bottom: 10px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.936);
  padding-bottom: 5px;
  max-width: 70px;
  font-size: 18px;
}

.footer a {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
}

.footer-icons {
  margin-top: 40px;
  font-size: 40px;
}

.footer-icons i {
  background-color: rgb(208, 208, 208);
  color: #000000;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  width: 50px;
  height: 50px;
  padding: 5px;
}

.footer p {
  font-size: 16px;
  line-height: 1.3;
}

.footer-icons-content {
  display: flex;
  gap: 10px;
}

.copy__container {
  background-color: #2c3338;
}

.copy {
  margin: auto;
  color: white;
  width: fit-content;
  padding: 20px;
}

.copy a {
  text-decoration: underline;
  color: #fff;
}

.footer__menu {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer__menu a {
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  padding-bottom: 5px;
}

.footer a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .footer .footer__child {
    flex: 0 0 30.3%;
    max-width: 30.3%;
  }
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    gap: 40px;
  }

  .footer__menu {
    gap: 10px;
  }
}
