.painel-container {
  padding: 20px;
  color: #3c434a;
  width: 100%;
  background-color: #f0f0f1;
}

.painel-header h3 {
  margin-bottom: 25px;
}

.painel-header p {
  margin-bottom: 10px;
  font-weight: 400;
}

.painel-content-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.painel-child {
  background-color: #fff;
  padding: 15px;
  width: 100%;
}

.painel-child .toggle-btn {
  margin-top: 20px;
}
