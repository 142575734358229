.lista-probono ul {
  margin-top: 10px;
    grid-gap: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.lista-probono li {
  min-height: 150px;
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
}

.lista-probono li p {
  line-height: 1.2;
}

.lista-probono button {
  margin-top: 5px;
}

.lista-probono {
  margin-top: 20px;
}

.probono-aplicante-descricao{
  flex: 1;
}


.modal {
  display: block; /* Garante que o modal seja exibido */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.767); /* Fundo escuro semi-transparente */
  overflow: auto; /* Permite rolar a tela se o conteúdo do modal for maior que a janela do navegador */
}

.modal-content {
  background-color: #fefefe;
  margin: 10% auto; /* Centraliza o modal verticalmente e o coloca a 10% da parte superior da tela */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Largura do modal */
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 18px;
  line-height: 1.3;
  max-width: 650px;
  position: relative;
}

.close {
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 5px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


@media (max-width: 768px) {
  .lista-probono li {
  min-height: 0;
  }
}