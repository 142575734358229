
.perfil-container{
    min-height: 90vh;
    width: 100%;
    padding: 20px;
}

.profile-container {
  border: 1px solid #ccc;
  padding: 20px;
}

.profile-header {
  text-align: center;
  margin-bottom: 20px;
}

.profile-info {
  display: flex;
  align-items: center;
}

.avatar {
    background-color: #ccc;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.perfil-container .logout-btn-perfil{
    text-align: right;
    margin-top: 20px;
}

.logout-btn-perfil .main-btn{
    margin-left: auto;
    margin-top: 20px;
}